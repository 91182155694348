import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemarkComponent } from './Components/remark/remark.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchFilterPipe } from './Pipes/search-filter.pipe';
import { ThaiDatePipe } from '@shared/Pipes/thaidate.pipe';
import {
  FileNamePipe,
  ImagePipe,
  NonImagePipe,
  PermissionPipe,
} from '@shared/Pipes/AppPipe';
import { DatepickerComponent } from './Components/datepicker/datepicker.component';
import { DropdownComponent } from './Components/dropdown/dropdown.component';
import { ModelEditOrderRefComponent } from './Components/model-edit-order-ref/model-edit-order-ref.component';
import { AdsBannerCarouselComponent } from './Components/ads-banner-carousel/ads-banner-carousel.component';
import { TruckPlateComponent } from './Components/truck-plate/truck-plate.component';
import { ButtonInsureReminderComponent } from './Components/button-insure-reminder/button-insure-reminder.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalConfirmComponent } from './Components/modal-confirm/modal-confirm.component';
import { StepBarComponent } from './Components/step-bar/step-bar.component';
import { StepButtonComponent } from './Components/step-button/step-button.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LightboxModule } from 'ngx-lightbox';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ModalQrReaderComponent } from './Components/modal-qr-reader/modal-qr-reader.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { QrWriterComponent } from './Components/qr-writer/qr-writer.component';
import { ModalSuccessComponent } from './Components/modal-success/modal-success.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TstImageListComponent } from './Components/tst-image-list/tst-image-list.component';
import { GalleriaModule } from 'primeng/galleria';
import { ClipboardModule } from 'ngx-clipboard';
import { ModalCopyClipboardComponent } from './Components/modal-copy-clipboard/modal-copy-clipboard.component';
import { HeaderFileComponent } from './Components/header-file/header-file.component';
import { FleetDropdownComponent } from '@app/modules/truck-management/components/fleet-dropdown/fleet-dropdown.component';
import { ModalAddFleetComponent } from '@app/modules/truck-management/Modal/modal-add-fleet/modal-add-fleet.component';
import { ModalEditUserComponent } from '@app/modules/team/components/modal-edit-user/modal-edit-user.component';
import { GooglemapModule } from './Components/ModalGoogleMap/modalgooglemap/googlemap/googlemap.module';
import { ModalDangerComponent } from './Components/modal-danger/modal-danger.component';

import { GalleryModule, GALLERY_CONFIG } from 'ng-gallery';
import { LIGHTBOX_CONFIG } from 'ng-gallery/lightbox';
import { ChangeFleetFormComponent } from '@app/modules/truck-license-management/components/change-fleet-form/change-fleet-form.component';
import { ModalShipmentTruckDriverComponent } from './Components/modal-shipment-truck-driver/modal-shipment-truck-driver.component';
import { ModalContactCenterComponent } from './Components/modal-contact-center/modal-contact-center.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { InputMaskModule } from 'primeng/inputmask';
import { NgOtpInputModule } from 'ng-otp-input';
import { PasswordModule } from 'primeng/password';
import { AngularResizedEventModule } from 'angular-resize-event';
import { DynamicBreadcrumbModule } from '@app/core/dynamic-breadcrumb/dynamic-breadcrumb.module';
import { TruckpickerBasicComponent } from './Components/truckpicker-basic/truckpicker-basic.component';
import { StepComponent } from './Components/step-wizard/components/step/step.component';
import { AlertMissionComponent } from './Components/alert-mission/alert-mission.component';
import { MapDirectionsComponent } from './Components/map-directions/map-directions.component';
import { TimePickerComponent } from './Components/time-picker/time-picker.component';
import { ModalConfirmPriceComponent } from './Components/modal-confirm-price/modal-confirm-price.component';
import { NumberWithTwoDecimalDirective } from './Directives/number-with-two-decimal';
import { NumberWithoutDecimalDirective } from './Directives/number-without-decimal';
import { BannerComponent } from './Components/banner/banner.component';
import { GoogleMapPolygonComponent } from './Components/google-map-polygon/google-map-polygon.component';

// import { registerLocaleData } from '@angular/common';
// import localeTh from '@angular/common/locales/th';

// registerLocaleData(localeTh, 'th');

// รวม component ที่ใช้ใน module ทั้งหมดไว้ที่นี้เพิ่มเอาไป ใส่ใน declarations ของ module.ts
export const SharedComps = [
  RemarkComponent,
  DatepickerComponent,
  DropdownComponent,
  ModelEditOrderRefComponent,
  AdsBannerCarouselComponent,
  TruckPlateComponent,
  ButtonInsureReminderComponent,
  ModalConfirmComponent,
  StepBarComponent,
  StepButtonComponent,
  ModalQrReaderComponent,
  ModalSuccessComponent,
  TstImageListComponent,
  ModalCopyClipboardComponent,
  HeaderFileComponent,
  FleetDropdownComponent,

  ModalAddFleetComponent,

  ModalEditUserComponent,
  ModalDangerComponent,
  ChangeFleetFormComponent,
  ModalShipmentTruckDriverComponent,
  ModalContactCenterComponent,
  StepComponent,
  AlertMissionComponent,
  MapDirectionsComponent,
  TimePickerComponent,
  ModalConfirmPriceComponent,
  BannerComponent,
  GoogleMapPolygonComponent
]

@NgModule({
  declarations: [
    SharedComps,
    SearchFilterPipe,
    ThaiDatePipe,
    FileNamePipe,
    NumberWithTwoDecimalDirective,
    NumberWithoutDecimalDirective,
    PermissionPipe,
    ImagePipe,
    NonImagePipe,
    ModelEditOrderRefComponent,
    AdsBannerCarouselComponent,
    TruckPlateComponent,
    ButtonInsureReminderComponent,
    ModalConfirmComponent,
    StepBarComponent,
    StepButtonComponent,
    ModalQrReaderComponent,
    QrWriterComponent,
    ChangeFleetFormComponent,
    ModalConfirmPriceComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    LightboxModule,
    NgxDropzoneModule,
    QRCodeModule,
    NgQrScannerModule,
    NgbModule,
    NgxPaginationModule,
    NgSelectModule,
    GalleriaModule,
    ClipboardModule,
    GooglemapModule,
    GalleryModule,
    LightboxModule,
    ZXingScannerModule,
    InputMaskModule,
    NgOtpInputModule,
    PasswordModule,
    AngularResizedEventModule,
    DynamicBreadcrumbModule,
  ],
  exports: [
    SharedComps,
    SearchFilterPipe,
    ThaiDatePipe,
    FileNamePipe,
    NumberWithTwoDecimalDirective,
    NumberWithoutDecimalDirective,
    PermissionPipe,
    ImagePipe,
    NonImagePipe,
    FormsModule,
    ReactiveFormsModule,
    QRCodeModule,
    ModalQrReaderComponent,
    QrWriterComponent,
    NgbModule,
    FlexLayoutModule,
    LightboxModule,
    NgxPaginationModule,
    NgxDropzoneModule,
    NgSelectModule,
    GalleriaModule,
    ClipboardModule,
    GalleryModule,
    LightboxModule,
    InputMaskModule,
    NgOtpInputModule,
    PasswordModule,
    AngularResizedEventModule,
    DynamicBreadcrumbModule,
  ],
  providers: [
    {
      provide: GALLERY_CONFIG,
      useValue: {
        dots: true,
        // imageSize: 'cover',
        thumbPosition: 'bottom',
        counter: true,
        imageSize: 'contain',
        zoomOut: 2,
      },
    },
    {
      provide: LIGHTBOX_CONFIG,
      useValue: {
        keyboardShortcuts: false,
        // panelClass: 'fullscreen'
      },
    },
  ],
})
export class SharedModule {}
