import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { User } from '@app/core/_Models/UserModel/User';
import { AuthenticationService, PageloadingService } from '@app/core/_Services';
import { TruckBookingAssignShipmentDto } from '@app/modules/booking/models/2022/booking-assign-model';
import { AddTruckDriverRequest, ShipmentBasicDto, ShipmentDriverModel, ShipmentTailModel, ShipmentTruckModel } from '@app/modules/booking/models/2022/shipment-model';
import { BookingV2022Service } from '@app/modules/booking/service/2022/booking-v2022.service';
import { CompanyTailDto, TailTypeDto } from '@app/modules/truck-license-management/models/company-tail-model';
import { CompanyTruckDto } from '@app/modules/truck-license-management/models/company-truck-model';
import { CompanyTailService } from '@app/modules/truck-license-management/services/company-tail.service';
import { CompanyTruckService } from '@app/modules/truck-license-management/services/company-truck.service';
import { TruckTypeDto } from '@app/modules/truck-management/models/TruckFleet';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ModalConfirmComponent, ModalConfirmModel } from '../modal-confirm/modal-confirm.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-modal-shipment-truck-driver',
  templateUrl: './modal-shipment-truck-driver.component.html',
  styleUrls: ['./modal-shipment-truck-driver.component.scss']
})
export class ModalShipmentTruckDriverComponent implements OnInit, OnChanges, OnDestroy {
  destroy: Subject<boolean> = new Subject<boolean>();
  @ViewChild("modalShipmentTruckDriver", { static: true }) modalShipmentTruckDriver!: ModalShipmentTruckDriverComponent;
  modalReference!: NgbModalRef;

  @Input() modalsize?: 'sm' | 'lg' | 'xl' | string;
  @Input() title_text: string = '';
  @Input() tabactive: number = 1;
  @Input() shipment: ShipmentBasicDto | TruckBookingAssignShipmentDto | any;
  @Input() public shipmentId: number = 0;
  @Input() truckBookingTruckTypeIds: number[] = []

  @Input() companyTruckSelectedId: number | undefined;
  @Input() companyTailSelectedId: number | undefined;
  driverSelectedId: number | undefined;
  @Input() truckTypeSelectedId: number | undefined;

  @Input() companyTruckTypeId: number = 0;
  @Input() truckTypes: TruckTypeDto[] = [];
  @Input() isOwnCarrier: boolean = null
  truckType: TruckTypeDto;


  @Output() btnadddriver_event = new EventEmitter<any>();
  @Output() btneditdriver_event = new EventEmitter<User>();

  @Output() btnaddcompanytruck_event = new EventEmitter<any>();
  @Output() btneditcompanytruck_event = new EventEmitter<CompanyTruckDto>();

  @Output() btnaddcompanytail_event = new EventEmitter<any>();
  @Output() btneditcompanytail_event = new EventEmitter<CompanyTailDto>();

  @Output() btnsubmit_event = new EventEmitter<boolean>();
  @Output() truckTypeChangeEvent = new EventEmitter<ShipmentBasicDto>();

  drivers_all: ShipmentDriverModel[] = [];
  public drivers: ShipmentDriverModel[] = [];
  public driver: ShipmentDriverModel;

  textsearchdriver: string = '';

  companytrucks_all: CompanyTruckDto[] = [];
  public companytrucks: CompanyTruckDto[] = [];
  public companytruck: CompanyTruckDto;
  shipmentTruckList: ShipmentTruckModel[] = [];
  textsearchcompanytruck: string = '';

  companytails_all: CompanyTailDto[] = [];
  public companytails: CompanyTailDto[] = [];
  public companytail: CompanyTailDto | undefined;
  shipmentTailList: ShipmentTailModel[] = [];
  tailTypeList: TailTypeDto[] = []

  textsearchcompanytail: string = '';

  @ViewChild("modalConfirms", { static: true }) modalConfirms!: ModalConfirmComponent;
  @ViewChild("modalConfirmtruck", { static: true }) modalConfirmtruck!: ModalConfirmComponent;
  constructor(
    // public pageLoad: PageloadingService,
    private modalService: NgbModal,
    private comTailService: CompanyTailService,
    private auth: AuthenticationService,
    private comTruckService: CompanyTruckService,
    private shipmentService: BookingV2022Service,
    private route: ActivatedRoute,
    private router: Router,

  ) {
    this.getCompanyTruckList();
    this.getCompanyTailList();
    this.comTailService.getTailTypeList();
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('companyTruckSelectedId', this.companyTruckSelectedId);
    this.companytruck = this.companytrucks && this.companytrucks.find(e => e.id == this.companyTruckSelectedId);
    this.companyTruckTypeId = this.companytruck?.companyTruckTypeId
    this.truckType = this.companytruck?.truckType;

    console.log('companyTailSelectedId', this.companyTailSelectedId);
    this.companytails = this.companytails_all || [];
    this.companytail = this.companytails && this.companytails.find(e => e.id == this.companyTailSelectedId);
    // debugger
    // if (!changes.driverSelectedId.firstChange) {
    //   if (changes.driverSelectedId && this.driverSelectedId) {
    //     this.shipmentService.shipmentDriverListOnChange.subscribe(val => {
    //       console.log('driverSelectedId', this.driverSelectedId);
    //       this.drivers_all = val || [];
    //       this.drivers = this.drivers_all || [];
    //       this.driver = this.drivers && this.drivers?.find(e => e.userId == this.driverSelectedId)
    //     });
    //   }

    // }



    this.onSubscribe();
  }
  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  ngOnInit() {
    this.tabactive = 1;
    console.log('this.shipment', this.shipment);

    if (this.shipment) {
      this.companyTruckSelectedId = this.shipment?.carrierCompanyTruckId;
      this.companyTailSelectedId = this.shipment?.carrierCompanyTailId;
      this.driverSelectedId = this.shipment?.carrierDriverId;
      this.onSubscribe();
    }
  }
  open(size?: 'sm' | 'lg' | 'xl' | string, tab?:number): NgbModalRef {
    this.ngOnInit();
    console.log('tablist_click',tab);
    if (size != undefined && size != '' && size != null) {
      this.modalReference = this.modalService.open(this.modalShipmentTruckDriver, { size: size, centered: true, backdrop: 'static' });
    }
    else {
      this.modalReference = this.modalService.open(this.modalShipmentTruckDriver, { centered: true });
    }
    if(tab){
      this.tablist_click(tab)
    }
    return this.modalReference;
  }

  onSubscribe(): void {
    this.companytrucks_all = []
    this.companytrucks = [];
    this.companytails_all = [];
    this.companytails = [];

    const companyId = this.auth.currentUserValue.tstS360_CompanyID;

    this.comTruckService.GetCompanyTruckListByShipmentId(this.shipmentId,companyId);

    this.comTruckService.shipmentTruckListOnChange.subscribe(val => {
      this.shipmentTruckList = val || [];
      console.log('this.shipmentTruckList', this.shipmentTruckList);

      // setTimeout(() => {
      //   this.comTruckService.companyTruckListOnChange.subscribe(val => {
      //     this.companytrucks_all = val.filter(e => this.truckBookingTruckTypeIds.includes(e.truckType_ID)) || [];
      //     this.companytrucks = this.companytrucks_all || [];

      //     this.companytruck = this.companytrucks && this.companytrucks.find(e => e.id == this.companyTruckSelectedId);
      //     this.companyTruckTypeId = this.companytruck?.companyTruckTypeId
      //     this.truckType = this.companytruck?.truckType;

      //   })
      // }, 1000)

    });
    this.comTailService.shipmentTailListOnChange.subscribe(val => {
      this.shipmentTailList = val || [];
      setTimeout(() => {
        this.comTailService.companyTailListOnChange.subscribe(val => {
          this.companytails_all = val || [];
          this.companytails = this.companytails_all || [];
          this.companytail = this.companytails && this.companytails.find(e => e.id == this.companyTailSelectedId);
        })
      }, 1000)
    });
    this.shipmentService.shipmentDriverListOnChange.subscribe(val => {
      this.drivers_all = val || [];
      this.drivers = this.drivers_all || [];
      this.driver = this.drivers && this.drivers?.find(e => e.userId == this.driverSelectedId)
    });
    this.shipmentService.driverIdOnChange.subscribe(driverId => {
      if (driverId > 0) {
        this.driverSelectedId = driverId;
        this.shipmentService.shipmentDriverListOnChange.subscribe(val => {
          this.drivers_all = val || [];
          this.drivers = this.drivers_all || [];
          this.driver = this.drivers?.find(e => e.userId == driverId)
        });
      }
    })
    this.comTailService.tailTypeListOnChange.subscribe(val => {
      this.tailTypeList = val || [];
    });

    this.comTruckService.companyTruckTypeListOnChange.subscribe(res => {
      this.truckTypes = [];
      res.map(e => {
        this.truckTypes = this.truckTypes.concat(e.truckTypeList)
      })
      this.truckTypes = this.truckTypes.filter(e => this.truckBookingTruckTypeIds?.includes(e.id)) || this.truckTypes
    })

    this.comTruckService.shipmentTruckListOnChangeForAssign.subscribe((res:any)=>{

      setTimeout(() => {
         this.companytrucks_all = res.filter(e => this.truckBookingTruckTypeIds.includes(e.truckType_ID)) || [];
          this.companytrucks = this.companytrucks_all || [];
          this.companytruck = this.companytrucks && this.companytrucks.find(e => e.id == this.companyTruckSelectedId);
          this.companyTruckTypeId = this.companytruck?.companyTruckTypeId
          this.truckType = this.companytruck?.truckType;
      }, 1000)
    });
  }

  getCompanyTruckList(): void {
    //this.pageLoad.show();
    this.comTruckService.getCompanyTruckList({
      fleetNo: null,
      truckTypeId: null,
      companyTruckTypeId: null,
      companyId: this.auth.currentUserValue.tstS360_CompanyID
    })
  }
  truckIsAvariable(id: number): boolean {
    if (this.shipmentTruckList.length > 0) {

      return this.shipmentTruckList.find(e => e.companyTruckId == id) ? false : true
    }
    return true;
  }
  tailIsAvariable(id: number): boolean {
    if (this.shipmentTailList.length > 0) {
      return this.shipmentTailList.find(e => e.companyTailId == id) ? false : true
    }
    return true;
  }

  getTailTypeName(id: number): string {
    return this.tailTypeList.find(e => e.id == id)?.typeName;
  }

  getCompanyTailList(): void {
    //this.pageLoad.show();
    this.comTailService.getCompanyTailList({
      truckTypeId: null,
      companyTruckTypeId: null,
      companyId: this.auth.currentUserValue.tstS360_CompanyID
    })
  }


  tablist_click(tab: number) {
    // set defualt trucktype select
    if (tab === 2) {
      this.truckTypeSelectedId = 0;
      this.truckTypeSelectedId = this.shipment?.truckTypeId;
      this.searchcompanytruck_click();
    }
    if (tab === 3) {
      if (this.tabactive == 2 && this.shipment.haveTail) {
        const trucktype = this.truckTypes?.find(f => f.id === this.truckTypeSelectedId);
        this.shipment.haveTail = trucktype.haveTail;
      }
    }
    this.tabactive = tab;
  }

  searchcompanytruck_click() {
    if (this.textsearchcompanytruck) {
      this.companytrucks = this.companytrucks_all.filter(ct =>
        ct.headLicenseId?.includes(this.textsearchcompanytruck) ||
        ct.tailLicense?.includes(this.textsearchcompanytruck) ||
        ct.truckType?.name?.includes(this.textsearchcompanytruck) ||
        ct.name?.includes(this.textsearchcompanytruck)
      );
      if (this.truckTypeSelectedId) {
        this.companytrucks = this.companytrucks.filter(e => e.truckType_ID == this.truckTypeSelectedId);
      }
    }
    else if (this.truckTypeSelectedId) {
      this.companytrucks = this.companytrucks_all.filter(e => e.truckType_ID == this.truckTypeSelectedId);
    }
    else {
      this.companytrucks = this.companytrucks_all;
    }
  }
  searchcompanytail_click() {
    if (this.textsearchcompanytail) {
      this.companytails = this.companytails_all.filter(ct =>
        ct.tailLicense?.includes(this.textsearchcompanytail) ||
        ct.tailType.typeName?.includes(this.textsearchcompanytruck) ||
        ct.tailName?.includes(this.textsearchcompanytail)
      );
      if (this.truckTypeSelectedId) {
        this.companytails = this.companytails.filter(e => e.truckType_ID == this.truckTypeSelectedId);
      }
    }
    else {
      this.companytails = this.companytails_all;
    }
  }
  searchdriver_click() {
    if (this.textsearchdriver) {
      this.drivers = this.drivers_all.filter(d =>
        d.fullname?.includes(this.textsearchdriver) ||
        d.mobileNo?.includes(this.textsearchdriver)
      );
    }
    else {
      this.drivers = this.drivers_all;
    }
  }
  driver_click(driver: ShipmentDriverModel) {
    this.driverSelectedId = driver.userId;
    this.shipmentService.driverIdOnChange.next(this.driverSelectedId);
    this.driver = driver;
    if (!driver.isAvariable) {
      const modalConfirmConfig = {
        title: 'คนขับคนนี้มีงานอยู่',
        description: 'คุณยืนยันที่จะเลือกคนขับนี้อยู่ใช่หรือไม่ ?',
        iconClassName: 'fal fa-exclamation-triangle text-warning',
        buttonList: [
          { text: 'ใช่', class: 'btn-success', comfirm: true },
          { text: 'ยกเลิก', class: 'btn-danger', comfirm: false },
        ],
      } as ModalConfirmModel;
      this.modalAlertOpen(modalConfirmConfig, 'driver');
    }
  }

  companytruck_click(companytruck: CompanyTruckDto) {
    this.companyTruckSelectedId = companytruck.id;
    this.companytruck = companytruck;
    this.companyTruckTypeId = this.companytruck?.companyTruckTypeId
    this.truckType = this.companytruck?.truckType;
    // this.shipment.haveTail = false;
    if (!this.truckIsAvariable(companytruck.id)) {
      const modalConfirmConfig = {
        title: 'รถคันนี้มีงานอยู่',
        description: 'คุณต้องการที่เลือกใช้ทะเบียนนี้อยู่ใช่หรือไม่ ?',
        iconClassName: 'fal fa-exclamation-triangle text-warning',
        buttonList: [
          { text: 'ใช่', class: 'btn-success', comfirm: true },
          { text: 'ยกเลิก', class: 'btn-danger', comfirm: false },
        ],
      } as ModalConfirmModel;
      // this.modalAlertOpen(modalConfirmConfig, 'truck');
      this.modalConfirmtruck.modalConfig = modalConfirmConfig;
      const modalRef = this.modalConfirmtruck.open('sm');
      modalRef.closed.subscribe(comfirm => {
        if (!comfirm) {
          this.companyTruckSelectedId = undefined;
          this.companytruck = undefined;
        }
      })
    }
    if (this.truckType.haveTail) {
      // this.shipment.haveTail = true;

      this.companyTailSelectedId = companytruck?.companyTail?.id;
      let companytail = this.companytails.find(ct => ct.id == this.companytruck.defaultCompanyTail_ID);
      if (companytail) {
        this.companyTailSelectedId = companytail.id;
        this.companytail = companytail;
      }
    }



  }
  companytail_click(companytail: CompanyTailDto) {
    this.companyTailSelectedId = companytail.id;
    this.companytail = companytail;

    if (!this.tailIsAvariable(companytail?.id)) {
      const modalConfirmConfig = {
        title: 'หางนี้มีงานอยู่',
        description: 'คุณต้องการที่เลือกให้ทะเบียนหางนี้อยู่ใช่หรือไม่ ?',
        iconClassName: 'fal fa-exclamation-triangle text-warning',
        buttonList: [
          { text: 'ใช่', class: 'btn-success', comfirm: true },
          { text: 'ยกเลิก', class: 'btn-danger', comfirm: false },
        ],
      } as ModalConfirmModel;
      this.modalAlertOpen(modalConfirmConfig, 'tail');
    }
  }

  disabledButtomConfirm(): boolean {
    return (this.tabactive == 1 && !this.driver) || (this.tabactive == 2 && !this.companytruck) || (this.tabactive == 3 && !this.companytail)
  }
  validatesubmit(): boolean {

    switch (this.tabactive) {
      case 1:
        if (!this.driverSelectedId) {
          const modalConfirmConfig = {
            title: 'แจ้งเตือน',
            description: 'กรุณาเลือกคนขับ',
            iconClassName: 'fal fa-exclamation-triangle text-warning',
            buttonList: [
              { text: 'ปิด', class: 'btn-outline-secondary', comfirm: false },
            ],
          } as ModalConfirmModel;
          this.modalAlertOpen(modalConfirmConfig, '');
          // this.modalAlertOpen(modalConfirmConfig);
          return false;
        }
        break;

      case 2:

        if (!this.companyTruckSelectedId) {
          const modalConfirmConfig = {
            title: 'แจ้งเตือน',
            description: 'กรุณาเลือกรถบรรทุก',
            iconClassName: 'fal fa-exclamation-triangle text-warning',
            buttonList: [
              { text: 'ปิด', class: 'btn-outline-secondary', comfirm: false },
            ],
          } as ModalConfirmModel;
          this.modalAlertOpen(modalConfirmConfig, '');
          // this.modalAlertOpen(modalConfirmConfig);
          return false;
        }
        break;

      case 3:
        if (!this.companyTailSelectedId) {
          const modalConfirmConfig = {
            title: 'แจ้งเตือน',
            description: 'กรุณาเลือกหาง',
            iconClassName: 'fal fa-exclamation-triangle text-warning',
            buttonList: [
              { text: 'ปิด', class: 'btn-outline-secondary', comfirm: false },
            ],
          } as ModalConfirmModel;
          this.modalAlertOpen(modalConfirmConfig, '');
          // this.modalAlertOpen(modalConfirmConfig);
          return false;
        }

        break;
    }
    return true;
  }
  btnnext_click() {
    if (this.validatesubmit()) {
      ++this.tabactive;
      this.tablist_click(this.tabactive);
    }
  }
  btnsubmit_click() {
    this.driver = this.drivers && this.drivers?.find(e => e.userId == this.driverSelectedId)
    this.companytruck = this.companytrucks && this.companytrucks?.find(e => e.id == this.companyTruckSelectedId)
    this.companytail = this.companytails && this.companytails?.find(e => e.id == this.companyTailSelectedId)
    this.driver = this.drivers && this.drivers?.find(e => e.userId == this.driverSelectedId)
    const isMobileApp = this.driver.isHasMobileApp;
    if (this.validatesubmit()) {
      let orderAddDriverRequest: AddTruckDriverRequest = new AddTruckDriverRequest();
      orderAddDriverRequest.driverUserId = this.driverSelectedId;
      orderAddDriverRequest.carrierCompanyTruckId = this.companyTruckSelectedId;
      orderAddDriverRequest.carrierCompanyTailId = this.companyTailSelectedId;
      orderAddDriverRequest.shipmentId = this.shipmentId;
      orderAddDriverRequest.actionBy = this.auth.currentUserValue?.tstS360_UserID;
      orderAddDriverRequest.shipmentStatusId = this.shipment?.shipmentStatusId < 10 ? 10 : this.shipment?.shipmentStatusId || 10
      orderAddDriverRequest.isOwnCarrier = this.isOwnCarrier
      this.shipmentService.saveShipmentTruckAndDriver(orderAddDriverRequest).then(r => {
        if (r.isSuccess) {
          this.modalReference.close(true);
          let modalDescription ="เพิ่มผู้ให้บริการขนส่งเรียบร้อย"
          let iconClassName = "fas fa-check text-success"
          if(!isMobileApp){
            modalDescription = `<h5>คำเตือน : ไม่พบ Application 360 TRUCKER <br/> ของ คนขับ <b>${this.driver.fullname}</b> โปรดตรวจสอบและติดตั้ง</h5>`
            iconClassName = "fas fa-exclamation-triangle text-warning"
          }
          const modalConfirmConfig = {
            title: 'ทำรายการสำเร็จ!',
            description: modalDescription,
            iconClassName: iconClassName,
            buttonList: [
              { text: 'ตกลง', class: 'btn-success', comfirm: true },
            ],
          } as ModalConfirmModel;
          this.driver = null;
          this.companytail = null;
          this.companytruck = null;
          this.modalConfirms.modalConfig = modalConfirmConfig
          const modalRef = this.modalConfirms.open('sm');
          modalRef.closed.subscribe(comfirm => {
            this.btnsubmit_event.emit(comfirm);
            this.shipmentService.driverIdOnChange.next(0);
            // this.ngOnInit();
          })
        }
      });
    }
  }

  modalAlertOpen(modalConfirmConfig: ModalConfirmModel, action: string): void {
    this.modalConfirms.modalConfig = modalConfirmConfig;
    const modalRef = this.modalConfirms.open('sm');
    modalRef.closed.subscribe(comfirm => {
      if (!comfirm) {
        if (action === 'truck') {
          this.companyTruckSelectedId = undefined;
          this.companytruck = undefined;
        } else if (action === 'tail') {
          this.companyTailSelectedId = undefined;
          this.companytail = undefined;
        } else if (action === 'driver') {
          this.driverSelectedId = undefined;
          this.driver = undefined;
        }
      }
    })
  }

  // event
  btnAddDriverClick(): void {
    this.modalReference.close(false);
    this.btnadddriver_event.emit(true);
  }
  btnEditDriverClick(driver: any): void {
    this.modalReference.close(false);
    this.btneditdriver_event.emit(driver);
  }
  btnAddTruckClick(): void {
    this.modalReference.close(false);
    // const companyTruck = this.companytrucks.find(e => e.id == this.shipment.carrierCompanyTruckId);
    this.btnaddcompanytruck_event.emit(null)
  }
  btnEditTruckClick(companyTruck: CompanyTruckDto): void {
    this.modalReference.close(false);
    this.btneditcompanytruck_event.emit(companyTruck);
  }
  btnAddTailClick(): void {
    this.modalReference.close(false);
    this.btnaddcompanytail_event.emit();
  }
  btnEditTailClick(tail: CompanyTailDto): void {
    this.modalReference.close(false);
    this.btneditcompanytail_event.emit(tail);
  }

  onLinkBooking(data:any){

    const url = this.router.serializeUrl(this.router.createUrlTree(['/booking'], { queryParams: {license: data?.headLicenseId, t: 'tab-all', st: '' } }));
    window.open(url, '_blank');
  }
}
