<ng-template #modalShipmentTruckDriver let-modal>
  <div class="modal-header pb-0">
    <div class="modal-title text-dark font-weight-bold w-100 text-center" id="modal-title"
      style="white-space: pre-wrap">
      <h4 class="modal-title text-dark font-weight-bold w-100 text-center" id="modal-sub-title"
        style="white-space: pre-wrap">{{title_text}}</h4>
      <h5 class="modal-title text-dark font-weight-bold w-100 text-center" id="modal-sub-title"
        style="white-space: pre-wrap">โปรดเลือกคนขับและรถบรรทุก</h5>

    </div>
    <button type="button" class="close pt-1 pl-0 pb-0 pr-1" aria-label="Close" data-dismiss="modal"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fal fa-times-circle"></i></span>
    </button>
  </div>
  <div class="modal-body pb-1">
    <div class="row align-items-center mb-4">
      <!-- Driver -->
      <div class="col-5">
        <div class="row align-items-center">
          <div class="col-auto pr-1">
            <img *ngIf="driver?.photo && driver?.photo != 'undefined'" src="{{driver?.photo}}" class="rounded-circle"
              style="width: 47.63px; height: 47.63px; " />
            <span *ngIf="!driver?.photo || driver?.photo == 'undefined'" class="fa-stack fa-lg ml-1 text-secondary"
              style="vertical-align:middle">
              <i class="fal fa-circle fa-stack-2x"></i>
              <i class="fal fa-user fa-stack-1x"></i>
            </span>
          </div>
          <div class="col-auto align-items-center pl-1">
            <div class="row">
              <div class="col-auto">
                <label *ngIf="!driver" class="h6 font-weight-bold text-danger mb-0">โปรดเลือกคนขับ</label>
                <label *ngIf="driver" class="h6 font-weight-bold text-dark mb-0">{{driver?.fullname}}</label>
              </div>
            </div>
            <div *ngIf="driver?.mobileNo" class="row">
              <div class="col-auto">
                <label class="text-dark mb-0">{{driver?.mobileNo}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Truck -->
      <div class="col-7">
        <div class="row align-items-center">
          <div class="col-auto px-1">
            <img *ngIf="companytruck?.companyTruckPhotos && companytruck?.companyTruckPhotos?.length > 0"
              src="{{companytruck?.companyTruckPhotos[0].filePath}}" class="rounded-circle"
              style="width: 47.63px; height: 47.63px; " />
            <span *ngIf="!companytruck?.companyTruckPhotos || companytruck?.companyTruckPhotos?.length == 0"
              class="fa-stack fa-lg ml-1"
              [ngClass]="{'text-success': companyTruckSelectedId == companytruck?.id, 'text-secondary': companyTruckSelectedId != companytruck?.id}"
              style="vertical-align:middle">
              <i class="fal fa-circle fa-stack-2x"></i>
              <i class="fal fa-truck fa-stack-1x"></i>
            </span>
            <!-- <img *ngIf="companytruck?.truckType" src="{{ '/assets/img/360techx/images/truckicon/'+companytruck?.truckType?.photo}}"  style="width: 80px; " />
            <span *ngIf="!companytruck?.truckType" class="fa-stack fa-lg ml-1" [ngClass]="{'text-success': companyTruckSelectedId&&companyTruckSelectedId == companytruck?.id, 'text-secondary': companyTruckSelectedId != companytruck?.id}" style="vertical-align:middle">
              <i class="fal fa-circle fa-stack-2x"></i>
              <i class="fal fa-truck fa-stack-1x"></i>
            </span> -->
          </div>
          <div class="col-auto align-items-center px-1">
            <ng-container *ngIf="companytruck; else noHasTruck">
              <!-- <div class="row">
                <div class="col-auto">
                  <label class="mb-0" [ngClass]="{'text-success': companyTruckSelectedId == companytruck?.id, 'text-dark': companyTruckSelectedId != companytruck?.id}">{{companytruck?.truckType?.name}}</label>
                </div>
              </div> -->
              <div class="row">
                <div class="col-auto">
                  <app-truck-plate *ngIf="companytruck?.headLicenseId" [title]="'หัว'"
                    [plateNumber]="companytruck?.headLicenseId" [province]="companytruck?.headLicenseProvince?.name">
                  </app-truck-plate>
                </div>
                <div class="col-auto" *ngIf="truckType?.haveTail && companytruck">
                  <app-truck-plate [title]="'หาง'" [plateNumber]="companytail?.tailLicense"
                    [province]="companytail?.tailLicenseProvince?.name"></app-truck-plate>
                </div>
              </div>
            </ng-container>
            <ng-template #noHasTruck>
              <div class="row">
                <div class="col-auto">
                  <label class="h6 font-weight-bold text-danger mb-0">โปรดเลือกทะเบียนรถ</label>
                </div>
              </div>
            </ng-template>

            <!-- <div *ngIf="!companytruck?.headLicenseId" class="row">
              <div class="col-auto">
                <label class="h6 font-weight-bold text-danger mb-0">โปรดเลือกทะเบียนรถ</label>
              </div>
            </div>
            <div *ngIf="companytruck?.headLicenseId" class="row">
              <div class="col-auto">
                <label class="h6 font-weight-bold text-dark mb-0">(หัว) {{companytruck?.headLicenseId}}</label>
              </div>
            </div>
            <div *ngIf="companytail" class="row">
              <div class="col-auto">
                <label class="h6 font-weight-bold text-dark mb-0">(หาง) {{companytail!.tailLicense!}}</label>
              </div>
            </div>
            <div *ngIf="companytruck?.truckTypeName" class="row">
              <div class="col-auto">
                <label class="text-dark mb-0">{{companytruck?.truckTypeName}}</label>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <ul class="nav nav-pills nav-justified" role="tablist">
      <li class="nav-item"><a class="nav-link border" [ngClass]="{'active bg-success btn-success': tabactive == 1}"
          data-toggle="tab" href="#driver" (click)="tablist_click(1)">คนขับ</a></li>
      <li class="nav-item"><a class="nav-link border" [ngClass]="{'active bg-success btn-success': tabactive == 2,
        'disabled': !driver, '': driver}" data-toggle="tab" href="#truck" (click)="tablist_click(2)">ทะเบียนรถ</a></li>
      <li *ngIf="truckType?.haveTail" class="nav-item"><a class="nav-link border"
          [ngClass]="{'active bg-success btn-success': tabactive == 3, 'disabled': !companytruck, '': companytruck}"
          data-toggle="tab" href="#tail" (click)="tablist_click(3)">ทะเบียนหาง</a></li>
    </ul>
    <div class="tab-content py-3">
      <!-- คนขับ -->
      <div id="driver" role="tabpanel" class="tab-pane active show fade" [ngClass]="{'active show': tabactive == 1}">
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-7 col-xl-7 pr-1">
            <span class="p-input-icon-right w-100">
              <i role="button" class="pi pi-search" (click)="searchdriver_click()"></i>
              <input pInputText type="text" class="form-control" placeholder="ค้นหารายชื่อคนขับ"
                [(ngModel)]="textsearchdriver" (keyup.enter)="searchdriver_click()" />
            </span>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-5 col-xl-5 pl-1">
            <button id="btnadddriver" class="btn btn-block btn-success" (click)="btnAddDriverClick()">
              <span class="fal fa-user-plus mr-1"></span>
              เพิ่มคนขับ
            </button>
          </div>
        </div>
        <hr class="my-2" />
        <div class="custom-scroll-vertical" style="height: 300px;">
          <div *ngFor="let driver of drivers" class="card mb-2"
            [ngClass]="{'border-success': driverSelectedId == driver?.userId}">
            <div class="card-body p-2">
              <div class="row align-items-center">
                <div class="col-1 pr-0 text-center">
                  <i *ngIf="driver?.isHasMobileApp" class="fal fa-mobile fa-2x" aria-hidden="true"></i>
                </div>
                <div class="col-auto pl-0 pr-1">
                  <img *ngIf="driver?.photo && driver?.photo != 'undefined'" src="{{driver?.photo}}"
                    class="rounded-circle" style="width: 47.63px; height: 47.63px; " />
                  <span *ngIf="!driver?.photo || driver?.photo == 'undefined'" class="fa-stack fa-lg ml-1"
                    [ngClass]="{'text-success': driverSelectedId == driver?.userId, 'text-secondary': driverSelectedId != driver?.userId}"
                    style="vertical-align:middle">
                    <i class="fal fa-circle fa-stack-2x"></i>
                    <i class="fal fa-user fa-stack-1x"></i>
                  </span>
                </div>
                <div class="col-auto align-items-center pl-1">
                  <div class="row">
                    <div class="col-auto">
                      <label class="h7 font-weight-bold mb-0"
                        [ngClass]="{'text-success': driverSelectedId == driver?.userId, 'text-dark': driverSelectedId != driver?.userId}">{{driver?.fullname}}
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <label class="mb-0"
                        [ngClass]="{'text-success': driverSelectedId == driver?.userId, 'text-dark': driverSelectedId != driver?.userId}">{{driver?.mobileNo}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-auto ml-auto">
                  <span class="badge badge-success p-2 mr-2" *ngIf="driver?.isAvariable">ว่างงาน</span>
                  <!-- <span class="badge badge-danger p-2 mr-2">ไม่ว่าง</span> -->
                  <button *ngIf="!driver?.isAvariable" type="button"
                    class="btn btn-sm btn-danger bg-brand-gradient mr-2" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    ไม่ว่าง <i class="fal fa-chevron-down ml-1"></i>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="/booking/details/{{item.truckBookingId}}?t=ASSIGN" target="_blank"
                      *ngFor="let item of driver.truckBookings">{{item?.truckBookingNo}}</a>
                    <!-- <button class="dropdown-item" type="button"
                      *ngFor="let item of driver.truckBookingNos"></button> -->
                  </div>
                  <button class="btn btn-sm mr-1"
                    [ngClass]="{'btn-outline-success': driverSelectedId == driver?.userId, 'btn-outline-primary': driverSelectedId != driver?.userId}"
                    (click)="btnEditDriverClick(driver)">
                    <span class="fal fa-edit mr-1"></span>
                    แก้ไข
                  </button>
                  <button class="btn btn-sm"
                    [ngClass]="{'btn-primary': driverSelectedId == driver?.userId, 'btn-success': driverSelectedId != driver?.userId}"
                    (click)="driver_click(driver)">{{driverSelectedId == driver?.userId ? 'เลือกคนขับคนนี้อยู่' :
                    'เลือกคนขับ'}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ทะเบียนรถ -->
      <div *ngIf="driver" id="truck" role="tabpanel" class="tab-pane fade" [ngClass]="{'active show': tabactive == 2}">
        <div class="row" *ngIf="truckTypes">
          <div class="col">
            <label for="">ประเภทรถ :</label>
            <ng-select [items]="truckTypes" bindValue="id" bindLabel="name" [(ngModel)]="truckTypeSelectedId"
              (change)="searchcompanytruck_click()" placeholder="เลือกประเภทรถ" [clearable]=false>
            </ng-select>
          </div>
        </div>
        <hr class="my-2" />
        <div class="row mt-2">
          <div class="col-6 col-sm-6 col-md-6 col-lg-7 col-xl-7 pr-1">
            <span class="p-input-icon-right w-100">
              <i role="button" class="pi pi-search" (click)="searchcompanytruck_click()"></i>
              <input pInputText type="text" class="form-control" placeholder="ค้นหาทะเบียนรถ"
                [(ngModel)]="textsearchcompanytruck" (keyup.enter)="searchcompanytruck_click()" />
            </span>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-5 col-xl-5 pl-1">
            <button class="btn btn-block btn-primary" (click)="btnAddTruckClick()">
              <span class="fal fa-truck mr-1"></span>
              เพิ่มทะเบียนรถ
            </button>
          </div>
        </div>
        <div class="custom-scroll-vertical mt-2" style="height: 300px;">
          <div *ngFor="let companytruck of companytrucks" class="card mb-2"
            [ngClass]="{'border-success': companyTruckSelectedId == companytruck?.id }">
            <div class="card-body p-2">
              <div class="row align-items-center">
                <div class="col-auto pr-1">
                  <img *ngIf="companytruck?.companyTruckPhotos && companytruck?.companyTruckPhotos?.length > 0"
                    src="{{companytruck?.companyTruckPhotos[0]?.filePath}}" class="rounded-circle"
                    style="width: 47.63px; height: 47.63px; " />
                  <span *ngIf="!companytruck?.companyTruckPhotos || companytruck?.companyTruckPhotos?.length == 0"
                    class="fa-stack fa-lg ml-1"
                    [ngClass]="{'text-secondary': companyTruckSelectedId == companytruck?.id, 'text-success': companyTruckSelectedId != companytruck?.id}"
                    style="vertical-align:middle">
                    <i class="fal fa-circle fa-stack-2x"></i>
                    <i class="fal fa-truck fa-stack-1x"></i>
                  </span>

                  <!-- <img *ngIf="companytruck?.truckType" src="{{ '/assets/img/360techx/images/truckicon/'+companytruck?.truckType?.photo}}"  style="width: 80px; " />
                  <span *ngIf="!companytruck?.truckType" class="fa-stack fa-lg ml-1" [ngClass]="{'text-success': companyTruckSelectedId == companytruck?.id, 'text-secondary': companyTruckSelectedId != companytruck?.id}" style="vertical-align:middle">
                    <i class="fal fa-circle fa-stack-2x"></i>
                    <i class="fal fa-truck fa-stack-1x"></i>
                  </span> -->
                </div>
                <div class="col-auto align-items-center pl-1">
                  <div class="row">
                    <div class="col-auto">
                      <label class="mb-0"
                        [ngClass]="{'text-dark': companyTruckSelectedId == companytruck?.id, 'text-success': companyTruckSelectedId != companytruck?.id}">{{companytruck?.truckType?.name}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <app-truck-plate *ngIf="companytruck?.headLicenseId" [title]="'หัว'"
                        [plateNumber]="companytruck?.headLicenseId"
                        [province]="companytruck?.headLicenseProvince?.name"></app-truck-plate>
                      <!-- <label *ngIf="companytruck?.headLicenseId" class="h6 font-weight-bold mb-0" [ngClass]="{'text-success': companyTruckSelectedId == companytruck?.id, 'text-dark': companyTruckSelectedId != companytruck?.id}">(หัว) {{companytruck?.headLicenseId}} {{companytruck?.headLicenseProvince?.name}}</label> -->

                    </div>
                    <!-- <div class="col-auto">
                      <app-truck-plate *ngIf="companytruck?.defaultCompanyTail_ID" [title]="'หาง'"
                        [plateNumber]="companytruck?.companyTail?.tailLicense"
                        [province]="companytruck?.companyTail?.tailLicenseProvince?.name"></app-truck-plate>
                    </div> -->
                  </div>
                  <!-- <div class="row">

                  </div> -->

                </div>
                <div class="col-auto ml-auto">
                  <!-- <span class="badge badge-success p-2 mr-2" *ngIf="truckIsAvariable(companytruck?.id) == true">ว่างงาน</span>
                  <span class="badge badge-danger p-2 mr-2"  *ngIf="truckIsAvariable(companytruck?.id) == false">ไม่ว่าง</span> -->
                  <!-- <a  *ngIf="companytruck?.shipmentBackLogs?.length == 0" class="badge badge-success p-2 mr-2" [routerLink]="['/booking']" [queryParams]="{license:companytruck?.headLicenseId, t: 'tab-all', st: '' }" target="_blank">ว่างงาน</a> -->
                  <span  *ngIf="companytruck?.shipmentBackLogs?.length == 0" class="badge badge-success p-2 mr-2" (click)="onLinkBooking(companytruck)"  style="text-decoration:underline;cursor: pointer;">ว่างงาน</span>
                  <button  *ngIf="companytruck?.shipmentBackLogs?.length != 0" type="button" class="btn btn-sm btn-danger bg-brand-gradient mr-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> ไม่ว่าง <i class="fal fa-chevron-down ml-1"></i>
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="/booking/details/{{item.truckBookingId}}?t=TRACKING" target="_blank" *ngFor="let item of companytruck?.shipmentBackLogs">{{item?.truckBookingNo}}</a>
                </div>
                  <!-- <span class="badge badge-danger p-2 mr-2"  *ngIf="companytruck?.shipmentBackLogs?.length != 0">ไม่ว่าง</span> -->
                  <button class="btn btn-sm mr-1"
                    [ngClass]="{'btn-outline-primary': companyTruckSelectedId == companytruck?.id, 'btn-outline-success': companyTruckSelectedId != companytruck?.id}"
                    (click)="btnEditTruckClick(companytruck)">
                    <span class="fal fa-edit mr-1"></span>
                    แก้ไข
                  </button>
                  <button class="btn btn-sm"
                    [ngClass]="{'btn-primary': companyTruckSelectedId == companytruck?.id, 'btn-success': companyTruckSelectedId != companytruck?.id}"
                    (click)="companytruck_click(companytruck)"> {{companyTruckSelectedId == companytruck?.id
                    ?'เลือกทะเบียนรถนี้อยู่':'เลือกทะเบียนรถ'}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ทะเบียนหาง -->
      <div *ngIf="truckType?.haveTail && companytruck" id="truck" role="tabpanel" class="tab-pane fade"
        [ngClass]="{'active show': tabactive == 3}">
        <div class="row">
          <div class="col-6 col-sm-6 col-md-6 col-lg-7 col-xl-7 pr-1">
            <span class="p-input-icon-right w-100">
              <i role="button" class="pi pi-search" (click)="searchcompanytail_click()"></i>
              <input pInputText type="text" class="form-control" placeholder="ค้นหาทะเบียนหาง"
                [(ngModel)]="textsearchcompanytail" (keyup.enter)="searchcompanytail_click()" />
            </span>
          </div>
          <div class="col-6 col-sm-6 col-md-6 col-lg-5 col-xl-5 pl-1">
            <button class="btn btn-block btn-success" (click)="btnAddTailClick()">
              <span class="fal fa-truck mr-1"></span>
              เพิ่มทะเบียนหาง
            </button>
          </div>
        </div>
        <hr class="my-2" />
        <div class="custom-scroll-vertical" style="height: 300px;">
          <div *ngFor="let companytail of companytails" class="card mb-2"
            [ngClass]="{'border-success': companyTailSelectedId ==  companytail?.id}">
            <div class="card-body p-2">
              <div class="row align-items-center">
                <div class="col-auto pr-1">
                  <img *ngIf="companytail?.companyTailPhotos && companytail?.companyTailPhotos?.length > 0"
                    src="{{companytail?.companyTailPhotos[0].filePath}}" class="rounded-circle"
                    style="width: 47.63px; height: 47.63px; " />
                  <span *ngIf="!companytail?.companyTailPhotos || companytail?.companyTailPhotos?.length == 0"
                    class="fa-stack fa-lg ml-1"
                    [ngClass]="{'text-secondary': companyTailSelectedId ==  companytail?.id, 'text-success': companyTailSelectedId !=  companytail?.id}"
                    style="vertical-align:middle">
                    <i class="fal fa-circle fa-stack-2x"></i>
                    <i class="fal fa-truck fa-stack-1x"></i>
                  </span>
                </div>
                <div class="col-auto align-items-center pl-1">
                  <div class="row">
                    <div class="col-auto">
                      <label class="mb-0"
                        [ngClass]="{'text-dark': companyTailSelectedId ==  companytail?.id, 'text-success': companyTailSelectedId !=  companytail?.id}">{{getTailTypeName(companytail?.tailType_ID)}}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <app-truck-plate [title]="'หาง'" [plateNumber]="companytail?.tailLicense"
                        [province]="companytail?.tailLicenseProvince?.name"></app-truck-plate>
                      <!-- <label *ngIf="companytail?.tailLicense" class="h6 font-weight-bold mb-0" [ngClass]="{'text-success': companyTailSelectedId ==  companytail?.id, 'text-dark': companyTailSelectedId !=  companytail?.id}">
                        (หาง) {{companytail?.tailLicense}}
                        <span *ngIf="companytail.tailLicenseProvince && companytail?.tailLicenseProvince.name">{{companytail.tailLicenseProvince.name}}</span>
                      </label> -->
                    </div>
                  </div>
                </div>
                <div class="col-auto ml-auto">
                  <span class="badge badge-success p-2 mr-2"
                    *ngIf="tailIsAvariable(companytail?.id) == true">ว่างงาน</span>
                  <span class="badge badge-danger p-2 mr-2"
                    *ngIf="tailIsAvariable(companytail?.id) == false">ไม่ว่าง</span>
                  <button class="btn btn-sm mr-1"
                    [ngClass]="{'btn-outline-primary': companyTailSelectedId ==  companytail?.id, 'btn-outline-success': companyTailSelectedId !=  companytail?.id}"
                    (click)="btnEditTailClick(companytail)">
                    <span class="fal fa-edit mr-1"></span>
                    แก้ไข
                  </button>
                  <button class="btn btn-sm"
                    [ngClass]="{'btn-primary': companyTailSelectedId ==  companytail?.id, 'btn-success': companyTailSelectedId !=  companytail?.id}"
                    (click)="companytail_click(companytail)">{{companyTailSelectedId == companytail?.id
                    ?'เลือกทะเบียนหางนี้อยู่':'เลือกทะเบียนหาง'}} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-between pt-1">
    <button id="btncancel" type="button" class="btn btn-outline-secondary waves-effect waves-themed font-weight-bold"
      style="width:47%" (click)="modal.dismiss('Cross click')">ยกเลิก</button>
    <button *ngIf="this.tabactive == 1 || this.tabactive == 2 && truckType?.haveTail"
      [disabled]="disabledButtomConfirm()" id="btnNext" type="button"
      class="btn btn-primary waves-effect waves-themed font-weight-bold btn-block" style="width:47%"
      (click)="btnnext_click()">ถัดไป</button>
    <button *ngIf="this.tabactive == 2 && !truckType?.haveTail || this.tabactive == 3 && truckType?.haveTail"
      id="btnsubmit" type="button" class="btn btn-success waves-effect waves-themed font-weight-bold btn-block"
      style="width:47%" (click)="btnsubmit_click()">บันทึก</button>
  </div>
</ng-template>
<app-modal-confirm #modalConfirms></app-modal-confirm>
<app-modal-confirm #modalConfirmtruck></app-modal-confirm>