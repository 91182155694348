export class  BookingListSearch {
    clientId: number| null;
    clientCarrierId: number | null;
    statusId: number
    status: number
    originDestination: string;
    keyword: string;
    customerId: number;
    startDateFrom: Date;
    startDateTo: Date;

    endDateFrom: Date;
    endDateTo: Date;
    atdDateFrom: Date | null;
    atdDateTo: Date | null;

    limit: number = 100;
    offset: number = 0;
    delayStatus:string

    isHasAddOnExpense: boolean | null

    createdBy:string;
    licensePlateNumber: string;
    sortBy: string;
}
