import { BillReceiptModel } from "@app/core/_Models/BookingModel/BillreceiptModel";
import { BillModel } from "@app/core/_Models/BookingModel/BillModel";
import { BillReceiptDetailesModel } from "@app/core/_Models/BookingModel/BillReceiptDetailesModel";
import { BookingListDeliveredModel } from "@app/core/_Models/BookingModel/BookingListDeliveredModel";
import { ExpenseTypeModel } from "@app/core/_Models/BookingModel/ExpenseTypeModel";
import { ShipmentModel } from "@app/core/_Models/BookingModel/ShipmentModel";
import { ShipmentExpenseServiceIssued, ShipmentTrackingStatusModel, TruckBookingPoExpenses } from "@app/core/_Models/BookingModel/ShipmentTrackingStatusModel";
import { UntiPriceTypeModel } from "@app/core/_Models/BookingModel/UntiPriceTypeModel";
import { DocReturnTypeModel } from "@app/core/_Models/BookingModel/DocReturnTypeModel";
import { customer } from "@app/modules/master/model/CustomerModel";
import { DropdownModel } from "@app/core/_Models/MasterModel/DropdownModel";
export class BookingModel {
  // shipment: ShipmentModel[] = new Array<ShipmentModel>();
  shipmentTracking: ShipmentTrackingStatusModel = new ShipmentTrackingStatusModel;
  untiPrice : UntiPriceTypeModel[] = new Array<UntiPriceTypeModel>();
  expense: ExpenseTypeModel[] = new Array<ExpenseTypeModel>()
  delivery: BookingListDeliveredModel = new BookingListDeliveredModel()
  billPay: BillReceiptModel[] = new Array<BillReceiptModel>()
  billReceipt: BillReceiptModel[] = new Array<BillReceiptModel>()
  docType: DocReturnTypeModel[] = new Array<DocReturnTypeModel>();
  customer: customer[] = new Array<customer>()
  carrier: DropdownModel[] = new Array<DropdownModel>()
  shipment: ShipmentModel = new ShipmentModel();
  id: number

  badgeStatus: string;
  truckBookingStatusName: string;

  keyword: string;
  createdBy:string;
  text: string;
  isSearch: boolean
  originDestination: string
  clientCarrierId: number
  customerName: number
  customerId: number
  endDateFrom: Date
  endDateTo: Date
  atdDateFrom: Date
  atdDateTo: Date
  startDateFrom: Date
  startDateTo: Date
  shipmentID: string
  docStatusID: number
  status: string
  statusId: number
  clientId: number
  truckBookingId: number
  isApproveEnd: boolean
  poExpenses: TruckBookingPoExpenses[]
  shipmentExpenseServiceIssued: ShipmentExpenseServiceIssued[]
  billExpenses: BillReceiptDetailesModel
  isApprove: boolean
  action: string
  APBillReceiptId: number


  truckbookingLocationId: number
  shipmentId: number
  atDate: Date
  images: File[]
  documents: File[]
}

export class TrackingModel {
  shipmentID: number
  startDate?: Date
  endDate?: Date
}

export class TATrackingModel {
  truckbookingLocationId?: number
  shipmentId?: number
  ataDate?: Date
  ataLatitude?: number
  ataLongitude?: number
  atdDate?: Date
  atdLatitude?: number
  atdLongitude?: number
  ptaDate?: Date
  ptdDate?: Date
  confirmedType : number
  notDeliverRemark: string
}

export class FollowTrackingModel {
  shipmentId: number
  followupStatusId: number
  followupComment?: string
}
export class TDTrackingModel {
  id?: number
  truckbookingLocationId?: number
  shipmentId?: number
  atdDate?: Date
  atdLatitude?: number
  atdLongitude?: number
  ptaDate?: Date
  ptdDate?: Date
  shipmentLocationTrackingFileAttach?: TrackingFile[] = new Array<TrackingFile>()
}
interface  TrackingFile {
  id?: number
  filePath?: string
  resourceUri?: string
  displayUri?: string
  isPhoto?: boolean
  valid?: boolean
}

export interface TruckBookingTeam{
  teamId:number;
  teamName:string;
  teamNumber:string;
}

